<nz-tabset nzSize="large">
  <nz-tab [nzTitle]="description">
    <ng-template #description>
      <span class="skiptranslate">
        {{ 'AUCTION.DESCRIPTION' | translate }}
      </span>
    </ng-template>
    <div class="translate">
      <nz-switch [(ngModel)]="translateEnabled"></nz-switch>
      <span class="skiptranslate">{{ 'AUCTION.TRANSLATE' | translate }}</span>
      <hr />
    </div>
    <div
      [ngClass]="['content', translateEnabled ? 'display-block' : 'display-none']"
      [innerHTML]="payload.Description"
    ></div>
    <div
      *ngIf="!translateEnabled"
      [ngClass]="['content', 'skiptranslate', !translateEnabled ? 'display-block' : 'display-none']"
      [innerHTML]="payload.Description"
    ></div>
  </nz-tab>
  <!-- [nzForceRender]="true" for correct translate -->
  <nz-tab [nzTitle]="qa" [nzForceRender]="true">
    <ng-template #qa>
      <span class="skiptranslate">
        {{ 'AUCTION.QA.QA' | translate }}
      </span>
    </ng-template>
    <div class="translate">
      <nz-switch [(ngModel)]="translateEnabled"></nz-switch>
      <span class="skiptranslate">{{ 'AUCTION.TRANSLATE' | translate }}</span>
      <hr />
    </div>
    <h4 nz-typography class="skiptranslate">{{ 'AUCTION.QA.ASK_SELLER' | translate }}</h4>
    <div *ngIf="isAuth" class="form">
      <div nz-row class="select-container">
        <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12" [nzLg]="12" [nzXl]="7">
          <nz-select
            [nzPlaceHolder]="'AUCTION.QA.SELECT_QUESTION' | translate"
            [(ngModel)]="selectedTemplate"
            (ngModelChange)="onTemplateChange($event)"
            nzDropdownClassName="skiptranslate"
          >
            <ng-container *ngFor="let template of messageTemplates">
              <nz-option [nzLabel]="template.title" [nzValue]="template"> </nz-option>
            </ng-container>
            <nz-option [nzLabel]="'AUCTION.QA.ASK_VIA_MANAGER' | translate" nzValue="custom">
            </nz-option>
          </nz-select>
          <form #f="ngForm" class="skiptranslate">
            <div *ngFor="let field of templateFields">
              <br />
              <ng-container [ngSwitch]="field.type">
                <ng-container *ngSwitchCase="'number'">
                  {{ 'TEMPLATE_FIELDS.' + (field.name | uppercase) | translate }}:
                  <input nz-input ngModel type="number" [name]="field.name" [placeholder]="10000" />
                </ng-container>
              </ng-container>
            </div>
          </form>
        </div>
      </div>
      <div nz-row class="textarea-container skiptranslate">
        <div
          *ngIf="selectedTemplate === 'custom'"
          nz-col
          [nzXs]="24"
          [nzSm]="24"
          [nzMd]="12"
          [nzLg]="12"
          [nzXl]="7"
        >
          <textarea
            nz-input
            [(ngModel)]="questionText"
            rows="5"
            [placeholder]="'AUCTION.QA.ENTER_QUESTION_TEXT' | translate"
          ></textarea>
        </div>
      </div>
      <div nz-row>
        <div nz-col>
          <button
            nz-button
            [nzLoading]="sending | async"
            [disabled]="!selectedTemplate"
            class="skiptranslate"
            nzType="primary"
            nzSize="large"
            (click)="ask(f.value)"
          >
            {{ 'AUCTION.QA.ASK' | translate }}
          </button>
        </div>
      </div>
      <hr />
    </div>
    <div *ngIf="!isAuth">
      <p class="skiptranslate">{{ 'AUCTION.QA.AUTH' | translate }}</p>
      <hr />
    </div>
    <nz-list nzBordered class="qa-list">
      <nz-list-item *ngFor="let qa of unasnweredQuestions" nzNoFlex>
        <div [ngClass]="translateEnabled ? 'display-block' : 'display-none'">
          <div class="question">
            <b> {{ 'AUCTION.QA.UNANSWERED_QUESTION' | translate }}: </b><br />
            <span class="hint">{{ qa.createdAt | date: 'short' }}</span
            ><br />
            <span class="body">{{ qa.content }}</span>
          </div>
        </div>
        <div
          *ngIf="!translateEnabled"
          [ngClass]="['skiptranslate', !translateEnabled ? 'display-block' : 'display-none']"
        >
          <div class="question">
            <b> {{ 'AUCTION.QA.UNANSWERED_QUESTION' | translate }}: </b><br />
            <span class="hint">{{ qa.createdAt | date: 'short' }}</span
            ><br />
            <span class="body">{{ qa.content }}</span>
          </div>
        </div>
      </nz-list-item>
      <nz-list-item *ngFor="let qa of qas" nzNoFlex>
        <div [ngClass]="translateEnabled ? 'display-block' : 'display-none'">
          <div class="question">
            <b>
              {{ 'AUCTION.QA.QUESTION_FROM' | translate }}
              {{ qa.Question.Id }}: </b
            ><br />
            <span class="hint">{{ qa.Question.Date | date: 'short' }}</span
            ><br />
            <span class="body">{{ qa.Question.Comment }}</span>
          </div>
          <div class="answer">
            <b> {{ 'AUCTION.QA.ANSWER' | translate }}: </b><br />
            <span class="hint">{{ qa.Answer.Date | date: 'short' }}</span
            ><br />
            <span class="body">{{ qa.Answer.Comment }}</span>
          </div>
        </div>
        <div
          *ngIf="!translateEnabled"
          [ngClass]="['skiptranslate', !translateEnabled ? 'display-block' : 'display-none']"
        >
          <div class="question">
            <b>
              {{ 'AUCTION.QA.QUESTION_FROM' | translate }}
              {{ qa.Question.Id }}: </b
            ><br />
            <span class="hint">{{ qa.Question.Date | date: 'short' }}</span
            ><br />
            <span class="body">{{ qa.Question.Comment }}</span>
          </div>
          <div class="answer">
            <b> {{ 'AUCTION.QA.ANSWER' | translate }}: </b><br />
            <span class="hint">{{ qa.Answer.Date | date: 'short' }}</span
            ><br />
            <span class="body">{{ qa.Answer.Comment }}</span>
          </div>
        </div>
      </nz-list-item>
    </nz-list>
  </nz-tab>

  <nz-tab [nzTitle]="history">
    <ng-template #history>
      <span class="skiptranslate">
        {{ 'AUCTION.BIDS_HISTORY.BIDS_HISTORY' | translate }}
      </span>
    </ng-template>
    <nz-table class="skiptranslate" #bidsTable [nzData]="history">
      <thead>
        <tr>
          <th>{{ 'AUCTION.BIDS_HISTORY.TIME' | translate }}</th>
          <th>{{ 'AUCTION.BIDS_HISTORY.LOGIN' | translate }}</th>
          <th>{{ 'AUCTION.BIDS_HISTORY.BID' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let bid of bidsTable.data">
          <td>{{ bid.Date | date: 'short' }}</td>
          <td>{{ getBidder(bid) }}</td>
          <td>{{ getBidPrice(bid) }}</td>
        </tr>
      </tbody>
    </nz-table>
  </nz-tab>
</nz-tabset>
