import { NotificationService, QueryHandlingService } from '@akebono/core';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  Country,
  DoorzoDeliveryRatesGQL,
  DoorzoDeliveryRatesQuery,
  DoorzoDeliveryRatesQueryVariables,
  DoorzoSupportedCountriesGQL,
  DoorzoSupportedCountriesQuery,
  DoorzoSupportedCountriesQueryVariables,
} from '../../../../graphql/user-service';
import { JpyExchangeRateService } from '../../../../services/jpy-exchange-rate.service';
import { ExtendedDoorzoDeliveryRate } from './interfaces/shipping-calculator.interface';

@Component({
  selector: 'app-shipping-calculator',
  templateUrl: './shipping-calculator.component.html',
  styleUrls: ['./shipping-calculator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShippingCalculatorComponent implements OnInit {
  deliveryRates$: Observable<ExtendedDoorzoDeliveryRate[]>;
  countries$: Observable<Country[]>;
  loading$: Observable<boolean>;
  deliveryRatesLoading$: Observable<boolean>;

  form: FormGroup = null;

  constructor(
    private fb: FormBuilder,
    private qhs: QueryHandlingService,
    private notificationService: NotificationService,
    private jpyExchangeRateService: JpyExchangeRateService,
    private doorzoDeliveryRatesGQL: DoorzoDeliveryRatesGQL,
    private doorzoSupportedCountriesGQL: DoorzoSupportedCountriesGQL,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.fetchCountries();
  }

  private initForm(): void {
    this.form = this.fb.group({
      weight: this.fb.control(null, [Validators.required]),
      country: this.fb.control(null, [Validators.required]),
    });
  }

  private fetchCountries(): void {
    const queryRef = this.qhs.fetch<
      DoorzoSupportedCountriesQuery,
      DoorzoSupportedCountriesQueryVariables
    >(this.doorzoSupportedCountriesGQL);
    this.loading$ = queryRef.loading;
    this.countries$ = queryRef.data.pipe(
      map(
        (result) =>
          [...result?.doorzoSupportedCountries]?.sort((a, b) => a.name.localeCompare(b.name)) || [],
      ),
    );
  }

  sendForCalcData(): void {
    const queryRef = this.qhs.fetch<DoorzoDeliveryRatesQuery, DoorzoDeliveryRatesQueryVariables>(
      this.doorzoDeliveryRatesGQL,
      {
        weight: +this.form.value.weight,
        countryIso: this.form.value.country,
      },
    );
    this.deliveryRatesLoading$ = queryRef.loading;
    this.deliveryRates$ = combineLatest([
      queryRef.data.pipe(map((result) => result.doorzoDeliveryRates)),
      this.jpyExchangeRateService.toUsdExchangeRate$,
    ]).pipe(
      map(([rates, exchangeRate]) =>
        rates.map((rate) => ({
          ...rate,
          totalFeeUsd: ((rate.totalFee ?? 0) / exchangeRate).toFixed(2),
        })),
      ),
      catchError((error) => {
        console.error('Error fetching delivery rates or exchange rate:', error);
        this.notificationService.renderError('SHIPPING_CALCULATOR.ERRORS.ERROR');
        this.deliveryRatesLoading$ = of(false);
        return of([]);
      }),
    );
  }

  shippingImgUrl(shippingMethod: string): string {
    const shippingMethodName = shippingMethod.replaceAll(' ', '-').toLowerCase();
    return `/assets/icons/shipping/${shippingMethodName}.png`;
  }
}
