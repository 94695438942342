import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subject, takeUntil } from 'rxjs';
import { RUSSIAN_CUSTOMS_CLEARANCE_INFO } from 'src/app/const';
import { AuctionQuery, ExchangeRate, YahooFeeConfigQuery } from 'src/app/graphql/user-service';
import { JpyExchangeRateService } from 'src/app/services/jpy-exchange-rate.service';
import { YahooFeeConfigService } from 'src/app/services/yahoo-fee-config.service';

import { PRICE_EMS } from '../../../../ems-price-grid';

@Component({
  selector: 'app-cost-calculator',
  templateUrl: './cost-calculator.component.html',
  styleUrls: ['./cost-calculator.component.scss'],
})
export class CostCalculatorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() price: any = null;
  @Input() jpDelivery: any = 0;
  @Input() suggestedJpDelivery: number = null;
  @Input() taxRate: any = null;
  @Input() isShop = true;
  @Input() allowBid = false;
  @Input() currentUser: AuctionQuery['currentUser'] = null;
  @Input() exchangeRate: ExchangeRate = null;
  @Input() bidStep = 0;
  @Input()
  set services(services: AuctionQuery['services']['nodes']) {
    this.allowedServices = (services ?? []).filter(
      (s) => !this.notAllowedServiceCodes.includes(s.code),
    );
  }

  isAuth = false;

  bid = 0;
  comissionCompany = 0;
  priceInJapan = 0;
  deliveryPrice = 0;
  totalPrice = 0;
  selectedWeight = 0;
  rate = null;
  wheelsOrRims = 4;
  wheelsOrRimsServiceCodes = ['akbWheelsDelivery', 'akbDisksDelivery'];
  jpyToEurExchangeRate: number;
  yahooFeeConfig: YahooFeeConfigQuery['yahooFeeConfig'];
  allowedServices: AuctionQuery['services']['nodes'] = [];

  weight: number[] = [0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.25, 1.5, 1.75, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5];
  private notAllowedServiceCodes = [
    'fastDelivery',
    'akbExpressCDEK',
    'akbLargeDelivery',
    'akbPersonal400Delivery',
    'akb400Delivery',
    'akb450Delivery',
    'akb1000Delivery',
    'akb1300Delivery',
  ];

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly jpyExchangeRateService: JpyExchangeRateService,
    private readonly yahooFeeConfigService: YahooFeeConfigService,
  ) {}

  ngOnInit(): void {
    for (let i = 6; i <= 200; i++) {
      this.weight.push(i);
    }

    this.isAuth = Boolean(this.currentUser);
    this.bid = Number(String(this.price).trim()) + (this.bidStep || 0);
    this.rate = 0;

    combineLatest([
      this.jpyExchangeRateService.toEurExchangeRate$,
      this.yahooFeeConfigService.yahooFeeConfig$,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([exchangeRate, feeConfig]) => {
        this.jpyToEurExchangeRate = exchangeRate;
        this.yahooFeeConfig = feeConfig;
        this.calculate();
      });

    this.calculate();
  }

  get isRussianUser(): boolean {
    const countryIso = this.currentUser?.countryIso;
    if (countryIso) {
      return countryIso.toLowerCase() === 'ru';
    } else {
      return true;
    }
  }

  ngOnChanges(changes) {
    console.log(changes);
    if (changes.price) {
      this.bid = Number(String(changes.price.currentValue).trim()) + (this.bidStep || 0);
      this.calculate();
    }
    if (changes.isShop) {
      this.calculate();
    }
  }

  calculate(): void {
    const taxRate = Number(String(this.taxRate).trim());
    const taxValue = this.getTax(this.bid, taxRate);
    const service = this.allowedServices.find((s) => s.code === this.rate);
    const customsFree = this.getCustomsFee(service?.code, this.bid);
    const deliveryPrice = this.getDeliveryPrice(
      service?.code,
      this.selectedWeight,
      service?.price.amount,
    );

    console.log(service?.code, this.selectedWeight, service?.price.amount);

    this.comissionCompany = this.calculateFee(this.bid);
    this.priceInJapan = this.bid + taxValue + this.comissionCompany + this.jpDelivery;
    this.deliveryPrice = deliveryPrice + customsFree;
    this.totalPrice = this.priceInJapan + this.deliveryPrice;
  }

  getTax(price: number, tax: number): number {
    return Number(((price * tax) / 100.0).toFixed());
  }

  private calculateFee(totalAmount: number | null): number {
    if (!this.yahooFeeConfig) {
      return 0;
    }

    if (!this.isShop) {
      return this.calculatePersonFee(totalAmount ?? 0);
    }

    const { percent: rate, minimum, maximum } = this.yahooFeeConfig;
    const percent = rate / 100;

    return Math.ceil(this.clamp((totalAmount ?? 0) * percent, minimum, maximum));
  }

  private calculatePersonFee(totalAmount: number): number {
    const { percent: rate, personTaxRate, minimum, maximum } = this.yahooFeeConfig;

    const percent = rate / 100;
    const baseCommission = Math.ceil(this.clamp(totalAmount * percent, minimum, maximum));

    return baseCommission + Math.ceil((personTaxRate / 100) * totalAmount);
  }

  getDeliveryPrice(deliveryService: string, weight: number, amountDelivery?: number): number {
    if (deliveryService) {
      const deliveryMain = [
        'akb500Delivery',
        'akb550Delivery',
        'akb1300Delivery',
        'akbPersonal400Delivery',
        'akbExpress092023',
        'akb1500Delivery',
      ];

      if (deliveryMain.includes(deliveryService)) {
        return weight * amountDelivery;
      } else if (this.wheelsOrRimsServiceCodes.includes(deliveryService)) {
        return (amountDelivery / 4) * this.wheelsOrRims;
      } else if (deliveryService === 'akbEnginesDelivery') {
        return amountDelivery;
      } else if (deliveryService === 'akbEMSDelivery') {
        const ems = PRICE_EMS.find((item) => item.weight === Number(weight));
        if (ems) {
          return ems.zone3_price;
        }
        return 0;
      } else {
        return 0;
      }
    }
    return 0;
  }

  getCustomsFee(deliveryService: string | null, price: number): number {
    if (!this.jpyToEurExchangeRate || !deliveryService || !price) {
      return 0;
    }

    if (deliveryService !== 'akbExpress092023') {
      return 0;
    }

    const { priceLimitEur, percentageOfExcess } = RUSSIAN_CUSTOMS_CLEARANCE_INFO;
    const priceLimitJpy = priceLimitEur * this.jpyToEurExchangeRate;
    const exceedingPrice = Math.ceil((price - priceLimitJpy) * percentageOfExcess);

    return Math.max(0, exceedingPrice);
  }

  private clamp(value: number, minimum: number, maximum: number): number {
    if (value < minimum) {
      return minimum;
    } else if (value > maximum) {
      return maximum;
    } else {
      return value;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
